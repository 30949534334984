/* For phones */

@media only screen and (max-width: 600px)
{

 
  /* First half of first page */

.intro-container{
  padding-top: 10%;
  background-color: #0f0e17;
  height: auto;
}
.about-info{
  margin-top: 5%; 
}
.picture{
  padding-bottom: 10%;   

}
.profile{
  /* border-radius: 50%; */
    width: 35vw;
    height: 50vw;
    /* margin-top: 10%;  */
}
.icons{
  padding-top: 5%;
  text-align: center;
}
.social-icons{
  fill: #a7a9be;
  height: 5vh;
  width: 5vh;
}
.social-icons:hover{
  fill: #ff8906;
}
#flag{
  height: 5vh;
  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;

}
@keyframes example {
  0%   {fill: black}
  25%  {fill: white}
  50%  {fill: black}
  100% {fill: white}
  
  }  

/* Second half of first page */

#skills-container{
  background-color: #fffffe;
  height: auto;
}
.skillCard{
  background-color: #0f0e17;
  border-radius: 5px 5px;
}


/*Second Page*/

.container-project{
  background-color: #0f0e17 !important;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  border-radius: 7px 7px !important;
  padding: 5%; 
  margin: 5%;
  vertical-align: middle;  
  height: auto;
}
.screenshot{
  display: block;
  width: auto;
  /* height: auto; */
  /* width: 70vw; */
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  padding-bottom: 10%;
  
}
.btn-more{
  background-color: #ff8906 !important;
  color: #fffffe !important;
}
#work-container{
  background-color: #fffffe !important;  

}

/*Contact Page*/

.container-contact{
  padding-top: 15%;
  background-color: #0f0e17;
  height: auto;  
}
.contact-btn{
  background-color: #ff8906 !important;
  color: #fffffe !important;
  border-radius: 5px 5px;
  height: 7vh;
  width: 20vw;
}
input, textarea{
  width: 70%;  
  height: 20%;
  background-color: #0f0e17;
  border-color: black;
  border-style: solid;
  border-width: 1%;
  padding: 2%;
}
.contacts{
  margin-left: 5%;
  /* padding-left: 10%; */
  padding-bottom: 10%;;
}
.c-row{
  padding-left: 5%;
  padding-top: 5%;
}
.d-row{
  padding-top: 5%;
  font-size: 1.5vw !important;
}
.social-link:hover{
  color: #ff8906 !important;
}
.social-link{  
  color: #a7a9be;
  font-size: 1.5vh;
}

/* General */
h1.light{
  color: #fffffe;
  font-size: 3vh;
}
h1.dark{
  color: #0f0e17;
  font-size: 3vh;
}
p.light{
  color: #a7a9be;
  font-size: 2vh;
}
p.dark{
  color:#2e2f3e;
  font-size: 2vh;

}
svg{
    fill: #ff8906;
    height: 5vh;
    width: 5vh;
}
.header{
  font-size: 5vh !important;
  font-weight: bold;  
  /* padding-top: 5%; */
}
.center{
  text-align: center;
}
.color-nav{
  background-color: #0f0e17;
}
.nav-link:hover{
  color: #ff8906 !important;
} 
.nav-link.active{
  color: #ff8906 !important;
}
.navbar-toggler-icon{
  border-color: #ff8906;
}
div {
  font-family: 'Montserrat';
}
nav{
  position: fixed !important;
  width: 100% !important;
}
.bottom{
  /* background-color: #0f0e17; */
  background-color: #0f0e17b6;
  height: 15vh !important;
  text-align: right;  
  padding-top: 5vh ;
}
.copyright{
  height: 5vw;
  width: 3vh;  
}

















* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}

html, body {
  height: 100%;
}

body {
  display: table;
  width: 100%;
  height: 100%;  
  color: #000;
  line-height: 1.6;
  position: relative;
  font-family: sans-serif;
  overflow: hidden;
}

.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
  opacity: 20%;
}
.lines .line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}
.lines .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(75%, #ffffff), to(#ffffff));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
  -webkit-animation: run 7s 0s infinite;
          animation: run 7s 0s infinite;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
          animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(1) {
  margin-left: -25%;
}
.lines .line:nth-child(1)::after {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.lines .line:nth-child(3) {
  margin-left: 25%;
}
.lines .line:nth-child(3)::after {
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}

@-webkit-keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

@keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

}



































































/* For iPad */

@media only screen and (min-width: 600px)
{
  /* First half of first page */

.intro-container{
  padding-top: 4%;
  background-color: #0f0e17;
  height: 70vh;
  padding-bottom: 5%;
}
.about-info{
  margin-top: 5%;
  margin-left: 10%;
}

.profile{
  /* border-radius: 50%; */
    width: 20vw;
    margin-top: 10%; 
}
.icons{
  padding-top: 5%;
  text-align: center;
}
.social-icons{
  fill: #a7a9be;
  height: 4vh;
  width: 4vh;
  
}
.social-icons:hover{
  fill: #ff8906;
}
#flag{
  height: 2vh;
  width:2vw;
  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;

}
@keyframes example {
  0%   {fill: black}
  25%  {fill: white}
  50%  {fill: black}
  100% {fill: white}
  
  }  

/* Second half of first page */

#skills-container{
  background-color: #fffffe;
  height: 50vh;
}
.skillCard{
  background-color: #0f0e17;
  border-radius: 5px 5px;
}


/*Second Page*/

.container-project{
  background-color: #0f0e17 !important;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  border-radius: 7px 7px !important;
  padding: 5%; 
  margin: 5%;
  vertical-align: middle;  
  height: auto;
}
.screenshot{
  display: block;
  width: auto;
  /* height: auto; */
  height: 20vh;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  
}
.btn-more{
  background-color: #ff8906 !important;
  color: #fffffe !important;
}
#work-container{
  background-color: #fffffe !important;
  padding-top: 4%;

}

/*Contact Page*/

.container-contact{
  padding-top: 5%;
  background-color: #0f0e17;
  height: 100vh;
  /* padding-bottom: 5%; */
}
.contacts{
  margin-left: 0%;
  /* padding-left: 10%; */
  padding-bottom: 10%;;
}
.contact-btn{
  background-color: #ff8906 !important;
  color: #fffffe !important;
  border-radius: 5px 5px;
  height: 7vh;
  width: 10vw;
}
input, textarea{
  width: 70%;  
  height: 20%;
  background-color: #0f0e17;
  border-color: black;
  border-style: solid;
  border-width: 1%;
  padding: 2%;
}

.c-row{
  padding-left: 10%;
  padding-top: 5%;

}
.d-row{
  padding-top: 5%;
  font-size: 1.5vw !important;
  padding-left: 5%;
}
.social-link:hover{
  color: #ff8906 !important;
}
.social-link{  
  color: #a7a9be;
  font-size: 1.5vh;
}

/* General */
h1.light{
  color: #fffffe;
  font-size: 2vw;
}
h1.dark{
  color: #0f0e17;
  font-size: 2vw;
}
p.light{
  color: #a7a9be;
  font-size: 1.3vw;
}
p.dark{
  color:#2e2f3e;
  font-size: 1.3vw;

}
svg{
    fill: #ff8906;
    height: 5vh;
    width: 5vh;
}
.header{
  font-size: 4vw !important;
  font-weight: bold;  
  /* padding-top: 5%; */
}
.center{
  text-align: center;
}
.color-nav{
  background-color: #0f0e17b6;
}
.nav-link:hover{
  color: #ff8906 !important;
} 
.nav-link.active{
  color: #ff8906 !important;
}

div {
  font-family: 'Montserrat';
}
nav{
  position: fixed !important;
  width: 100% !important;
}
.bottom{
  /* background-color: #0f0e17; */
  background-color: #0f0e17b6;
  height: 10vh !important;
  text-align: right;  
  padding-top: 3vh ;
}
.copyright{
  height: 2vw;
  width: 3vh;  
}

p.light{
  margin-bottom: 0%;
}

















* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}

html, body {
  height: 100%;
}

body {
  display: table;
  width: 100%;
  height: 100%;  
  color: #000;
  line-height: 1.6;
  position: relative;
  font-family: sans-serif;
  overflow: hidden;
}

.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
  opacity: 20%;
}
.lines .line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}
.lines .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(75%, #ffffff), to(#ffffff));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
  -webkit-animation: run 7s 0s infinite;
          animation: run 7s 0s infinite;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
          animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(1) {
  margin-left: -25%;
}
.lines .line:nth-child(1)::after {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.lines .line:nth-child(3) {
  margin-left: 25%;
}
.lines .line:nth-child(3)::after {
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}

@-webkit-keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

@keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

}

























































/* For laptops and large screens */
@media only screen and (min-width: 1200px) {


/* First half of first page */

.intro-container{
  padding-top: 4%;
  background-color: #0f0e17;
  background-image: url(../src/images/bgabout.gif);
  background-size: cover;
  height: 100vh;
}
.about-info{
  margin-top: 5%;
  margin-left: 10%;  
}

.profile{
  /* border-radius: 50%; */
    width: 20vw;
    margin-top: 10%; 
}
.icons{
  padding-top: 5%;
  text-align: center;
}
.social-icons{
  fill: #a7a9be;
  height: 5vh;
  width: 5vh;
}
.social-icons:hover{
  fill: #ff8906;
}
#flag{
  height: 5vh;
  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;

}
@keyframes example {
  0%   {fill: black}
  25%  {fill: white}
  50%  {fill: black}
  100% {fill: white}
  
  }  

/* Second half of first page */

#skills-container{
  background-color: #fffffe;
  height: 100vh;
}
.skillCard{
  background-color: #0f0e17;
  border-radius: 5px 5px;
}


/*Second Page*/

.container-project{
  background-color: #0f0e17 !important;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  border-radius: 7px 7px !important;
  padding: 5%; 
  margin: 5%;
  vertical-align: middle;  
  height: auto;
}
.screenshot{
  display: block;
  width: auto;
  /* height: auto; */
  height: 50vh;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  
}
.btn-more{
  background-color: #ff8906 !important;
  color: #fffffe !important;
}
#work-container{
  background-color: #fffffe !important;
  padding-top: 4%;

}

/*Contact Page*/

.container-contact{
  padding-top: 5%;
  background-color: #0f0e17;
  min-height: 110vh;
  /* padding-bottom: 5%; */
}
.contacts{
  margin-left: 0%;
  /* padding-left: 10%; */
}
.contact-btn{
  background-color: #ff8906 !important;
  color: #fffffe !important;
  border-radius: 5px 5px;
  height: 7vh;
  width: 10vw;
}
input, textarea{
  width: 70%;  
  height: 20%;
  background-color: #0f0e17;
  border-color: black;
  border-style: solid;
  border-width: 1%;
  padding: 2%;
}

.c-row{
  padding-left: 10%;
  padding-top: 5%;
}
.d-row{
  padding-top: 5%;
  padding-left: 5%;
  font-size: 1.3vw !important;
}
.social-link:hover{
  color: #ff8906 !important;
}
.social-link{
  font-size: 1.5vw;
  color: #a7a9be;
}

/* General */
h1.light{
  color: #fffffe;
  font-size: 2vw;
}
h1.dark{
  color: #0f0e17;
  font-size: 2vw;
}
p.light{
  color: #a7a9be;
  font-size: 1.3vw;
}
p.dark{
  color:#2e2f3e;
  font-size: 1.3vw;
}
.ish{
  color: white;
}
svg{
    fill: #ff8906;
    height: 10vh;
    width: 10vh;
}
.header{
  font-size: 4vw !important;
  font-weight: bold;  
  /* padding-top: 5%; */
}
.center{
  text-align: center;
}
.color-nav{
  background-color: #0f0e17b6;
}
.nav-link:hover{
  color: #ff8906 !important;
} 
.nav-link.active{
  color: #ff8906 !important;
}

div {
  font-family: 'Montserrat';
}
nav{
  position: fixed !important;
  width: 100% !important;
}
.bottom{
  /* background-color: #0f0e17; */
  background-color: #0f0e17b6;
  height: 20vh !important;
  text-align: right;  
  padding-top: 7vh ;
}
.copyright{
  height: 2vw;
  width: 3vh;  
}

















* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}

html, body {
  height: 100%;
}

body {
  display: table;
  width: 100%;
  height: 100%;  
  color: #000;
  line-height: 1.6;
  position: relative;
  font-family: sans-serif;
  overflow: hidden;
}

.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
  opacity: 20%;
}
.lines .line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}
.lines .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(75%, #ffffff), to(#ffffff));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
  -webkit-animation: run 7s 0s infinite;
          animation: run 7s 0s infinite;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
          animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(1) {
  margin-left: -25%;
}
.lines .line:nth-child(1)::after {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.lines .line:nth-child(3) {
  margin-left: 25%;
}
.lines .line:nth-child(3)::after {
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}

@-webkit-keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

@keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

  
}



.form-group{
  margin-bottom: 0 !important;
}
.white{
  color: white;
}
  
  
  
  
  
  
  
  

